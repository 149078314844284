//
//Jquery validation scss start
//
.error {
  &.invalid-feedback {
    width: auto;
    position: relative;
    margin-top: $spacer * 0.5;
    display: table;
    &::before {
      border-width: 1px 0 0 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      top: 0;
      left: 50%;
      background-color: $danger;
      position: absolute;
      content: '';
      z-index: 2;
      width: 0.6666em;
      height: 0.6666em;
    }
  }
}
.row.error {
  .form-control {
    border-color: $danger;
    &:focus {
      box-shadow: inset 0 1px 2px rgb($danger, 0.08),
        0 0 0.5rem 0.25rem rgb($danger, 0.13);
    }
  }
  .form-label {
    color: shade-color($danger, 20%);
  }
  .form-check-input {
    background-color: tint-color($danger, 80%);
  }
  .form-check-label {
    color: $danger;
  }
}
