//Kanban

.task-list-items {
  min-height: 100px;
  position: relative;
  .card {
    white-space: normal;
  }

  &:before {
    content: 'No Tasks';
    position: absolute;
    line-height: 110px;
    width: 100%;
    padding-left: 1.25rem;
    display: block;
    font-weight: $font-weight-bold;
    color: $text-muted;
    border: 1px dashed var(--#{$prefix}border-color);
    border-radius: $card-border-radius;
  }
}
