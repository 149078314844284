//Admin sidebar style
.page-sidebar,
.aside {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.page-sidebar {
  --navbar-light-active-color: #{$navbar-light-active-color};
  width: $page-sidebar-width;
  height: 100%;
  position: fixed;
  z-index: $zindex-fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  box-shadow: $sidebar-shadow;
  [data-simplebar] {
    overflow-x: hidden;
  }
  .aside-footer {
    min-width: $page-sidebar-width;
    transition: all 0.35s;
  }
  .nav-item:not(:last-child) {
    margin-bottom: 0.4rem;
  }
  .nav-link {
    width: 100%;
    padding: 0.2rem 0.5rem;
    font-weight: $sidebar-link-font-weight;
  }
  .simplebar-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .nav-link,
  .sidebar-link {
    &[data-bs-toggle='collapse'] {
      &::after {
        display: inline-block;
        font-size: $font-size-sm;
        margin-right: 0.5rem;
        margin-left: auto;
        content: '\f286';
        font-family: bootstrap-icons;
        font-weight: normal;
        transition: transform 0.15s ease-in-out;
        opacity: 0.85;
      }

      &[aria-expanded='true'] {
        color: $component-active-bg;

        &::after {
          transform: rotate(180deg);
        }
      }
      &.active {
        color: $component-active-bg;
      }

      &:hover {
        color: var(--navbar-light-active-color);
      }
    }
  }
}
.aside-menu {
  height: 100%;
  overflow-y: auto;
}
.sidebar-icon {
  border-radius: $border-radius;
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;

  > i {
    font-size: $font-size-base;
    line-height: 1;
    vertical-align: middle;
  }
}

.sidebar-dropdown {
  padding-left: 2.5rem;

  .sidebar-item {
    display: block;
    margin-top: 0.125rem;
    &:first-child {
      margin-top: 0.25rem;
    }
    .sidebar-link {
      font-size: $font-size-base;
      padding: 0.4rem 1rem;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-transform: capitalize;
    }
  }

  .sidebar-dropdown {
    padding-left: 0.5rem;
  }
}

//aside colors
.page-sidebar {
  --sidebar-bg: var(--#{$prefix}white);
  --sidebar-border-right-color: var(--#{$prefix}border-color);
  --sidebar-shadow: inset -3px 0px 5px rgba($gray-900, 0.1);
  --sidebar-brand-color: var(--#{$prefix}body-color);
  --sidebar-link-color: var(--#{$prefix}body-color);
  --sidebar-link-hover-color: var(--#{$prefix}link-color);
  --sidebar-link-hover-bg: var(--#{$prefix}tertiary-bg);
  --sidebar-link-active-bg: var(--#{$prefix}tertiary-bg);
  --sidebar-link-active-color: var(--#{$prefix}primary);
  background-color: var(--sidebar-bg);
  box-shadow: var(--sidebar-shadow);
  border-right: $border-width solid var(--sidebar-border-right-color);
  .aside-logo a,
  .sidebar-title {
    color: var(--sidebar-brand-color);
  }

  .nav-link,
  .nav-link[data-bs-toggle='collapse'],
  .sidebar-item .sidebar-link {
    color: var(--sidebar-link-color);
    border-radius: $border-radius;
    &:hover,
    &[aria-expanded='true'] {
      color: var(--sidebar-link-hover-color);
    }
    &:hover {
      background-color: var(--sidebar-link-hover-bg);
    }
    &.active {
      color: var(--sidebar-link-active-color);
      background-color: var(--sidebar-link-active-bg);
    }
  }
}

.sidebar-title {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  opacity: 0.6;
  font-size: $font-size-sm;

  > i {
    display: flex;
    margin-right: 0.5rem;
    width: 2.25rem;
    height: 2.25rem;
    justify-content: center;
    align-items: center;
  }
}

.page-header,
.aside-logo {
  height: 64px;
  display: flex;
  align-items: center;
}
.page-header .navbar-inner {
  background-color: $card-bg;
  box-shadow: $box-shadow;
}
.page-content {
  transition: margin 0.3s;
}

.page-sidebar {
  transition: width 0.3s;
}

//Icon arrow for collapse items

.layout-boxed {
  .page-content {
    background-color: var(--#{$prefix}body-bg);
    position: relative;
  }
}

@include media-breakpoint-up(lg) {
  body:not(.layout-boxed) .page-sidebar {
    height: 100%;
    left: 0;
    top: 0;
  }
  body:not(.layout-boxed).page-sidebar-mini .page-sidebar:hover {
    box-shadow: $box-shadow-lg;
  }
  .page-content {
    margin-left: $page-sidebar-width;
  }

  body.page-sidebar-mini {
    .page-sidebar:not(:hover) {
      width: $page-sidebar-compact-width;

      .sidebar-text,
      .sidebar-dropdown,
      .logo-text,
      .sidebar-title > span,
      .nav-link[data-bs-toggle='collapse']::after,
      .aside-info {
        display: none;
      }
      .sidebar-icon {
        margin-right: 0;
      }
    }

    .page-content {
      margin-left: $page-sidebar-compact-width;
    }
  }
}

@include media-breakpoint-down(lg) {
  .page-content {
    margin-left: 0px;
  }

  .page-sidebar {
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateX(-100%);
    z-index: $zindex-modal;
  }

  .sidebar-close {
    a {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: $zindex-fixed;
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
      backdrop-filter: blur(10px);
      transition: opacity 0.35s background-color 0.35s;
    }
  }
  .page-sidebar-in {
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    touch-action: none;
    -ms-touch-action: none;
    .page-sidebar {
      z-index: $zindex-modal;
      transform: translateX(0);
      box-shadow: 16px 0px 300px rgba($secondary, 0.225);
    }
    .page-content {
      pointer-events: none;
    }
    .sidebar-close {
      a {
        opacity: 1;
        visibility: visible;
        background-color: rgba($secondary, 0.5);
      }
    }
  }
}

//Sidebar dark colors
[data-bs-theme='dark'] {
  .page-sidebar {
    --sidebar-border-right-color: var(--#{$prefix}border-color);
    --sidebar-bg: var(--#{$prefix}body-bg);
    --sidebar-link-color: var(--#{$prefix}body-secondary-color);
    --sidebar-link-hover-color: var(--#{$prefix}white);
    --sidebar-link-hover-bg: var(--#{$prefix}gray-800);
    --sidebar-link-active-bg: var(--#{$prefix}gray-700);
    --sidebar-link-active-color: var(--#{$prefix}white);
    --sidebar-brand-color: var(--#{$prefix}white);
  }
}
