//
//Swiper slider css start
//
.swiper-button-next,
.swiper-button-prev {
  width: $spacer * 2;
  height: $spacer * 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: currentColor;
  border-radius: $border-radius;
  background-color: $swiper-navigation-bg;
  box-shadow: $box-shadow;
  margin-top: 0;
  transform: translateY(-50%);
  &:after {
    font-size: $font-size-base;
    font-weight: 900;
  }
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0;
}
.swiper-pagination-bullet {
  background-color: currentColor;
}
.swiper-pagination-bullet-active {
  background-color: currentColor;
  opacity: 1;
}
