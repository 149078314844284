/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-mdc-form-field {
  width: 100%;
}

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '@fortawesome/fontawesome-pro/css/all.css';

$--mat-expansion-container-background-color: none;

// regular style toast
@import 'ngx-toastr/toastr';

@import 'bootstrap/scss/functions';
@import 'scss/variables/variables';
@import 'scss/variables/variables-dark';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'scss/utilities/utilities';
@import 'bootstrap/scss/bootstrap';

@import 'scss/utilities/root';

// boostrap 5
@import 'ngx-toastr/toastr-bs5-alert';

@import 'scss/utilities/typography';
@import 'scss/utilities/flexbox';
@import 'scss/components/components';
@import 'scss/components/dataTables';
@import 'scss/webfonts/webfonts';

@import 'scss/custom';
