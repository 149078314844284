//
//Quill Editor override
//
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-color: var(--#{$prefix}border-color-translucent);
}
.ql-snow .ql-stroke {
  stroke: var(--#{$prefix}body-color);
}
.ql-snow .ql-fill {
  fill: var(--#{$prefix}body-color);
}
.ql-snow .ql-picker {
  color: var(--#{$prefix}body-color);
}
.ql-editor.ql-blank::before {
  color: $text-muted;
  left: $card-spacer-x;
}
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: $component-active-bg;
}
.ql-container {
  font-size: $font-family-base;
  font-family: $font-family-base;
}
.ql-editor {
  padding: $card-spacer-y $card-spacer-x;
  background-color: transparent;
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
  font-family: $font-family-base;
  min-height: 8rem;
  box-shadow: $card-box-shadow;
}
.ql-container {
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}
.ql-toolbar.ql-snow {
  box-shadow: $card-box-shadow;
  font-family: $font-family-base;
  padding: $card-cap-padding-y $card-cap-padding-x;
  background-color: transparent;
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: var(--#{$prefix}border-color-translucent);
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: var(--#{$prefix}body-color);
}
.ql-snow .ql-editor {
  a {
    text-decoration: none;
    font-weight: $font-weight-bold;
  }
  img {
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-lg;
  }
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: $dropdown-border-color;
  box-shadow: $box-shadow-lg;
  padding: $dropdown-padding-y $dropdown-padding-x;
}

.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
  background-color: $light;
  border-radius: $border-radius;
}
.ql-snow .ql-editor code {
  padding: 4px 8px;
}
.ql-snow .ql-editor blockquote {
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
  background: transparent;
  border: 1px solid var(--#{$prefix}border-color);
  border-left-width: 5px;
  border-left-color: $component-active-bg;
  padding-right: $spacer * 0.5;
  padding-top: $spacer * 0.5;
  padding-bottom: $spacer * 0.5;
}
.ql-snow .ql-picker-options {
  background-color: var(--#{$prefix}tertiary-bg);
}
