//Tables

table {
  th {
    font-weight: $font-weight-bold;
  }
}

.table-nowrap .table {
  td,
  th {
    white-space: nowrap;
  }
}
.table-card .table tbody tr:last-child td:first-child {
  border-bottom-left-radius: $card-border-radius;
}
.table-card .table tbody tr:last-child td:last-child {
  border-bottom-right-radius: $card-border-radius;
}
.table-card {
  .table tr:last-child {
    border-bottom-width: 0px;
    td,
    th {
      border-bottom-width: 0px;
    }
  }
}
.table-card {
  .table.table-bordered,
  .table.table-striped {
    thead tr th:first-child,
    tbody tr td:first-child {
      border-left-width: 0px;
    }
    thead tr th:last-child,
    tbody tr td:last-child {
      border-right-width: 0px;
    }
    tbody tr:last-child {
      border-bottom-width: 0px !important;
      td,
      th {
        border-bottom-width: 0px !important;
      }
    }
  }
}
