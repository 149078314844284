//
//Pagination style overrides
//

.pagination {
  .page-item {
    .page-link {
      border-radius: $border-radius-lg;
    }
    &:not(:first-child) {
      margin-left: 0.25rem;
    }
    &:not(:first-child) .page-link {
      margin-left: 0px;
    }
  }
}
