//custom style for accordions group (list-group-item)
.list-collapse-group {
  .list-group-item {
    [data-bs-toggle='collapse'] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding-right: $spacer * 1.5;
      &::after {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -$spacer * 0.75;
        content: '\e5cf';
        font-family: 'Material Symbols Rounded';
        font-size: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $spacer * 1.5;
        height: $spacer * 1.5;
        transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
      }
      &[aria-expanded='true'] {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }
}

//card collapsible
.card-collapse-btn {
  > span {
    display: inline-block;
    transition: transform 0.25s;
  }
  &[aria-expanded='true'] {
    > span {
      transform: rotate(180deg);
    }
  }
}
