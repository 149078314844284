html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  letter-spacing: $letter-spacing-base;
}

%heading {
  font-weight: $headings-font-weight;
  letter-spacing: $letter-spacing-headings;
}
* {
  &:focus {
    outline: 0 !important;
  }
}
a,
button {
  transition: $transition-base;
}

.text-mono {
  font-family: $font-family-monospace;
}

strong,
b {
  font-weight: $font-weight-bold;
}
a:not(.nav-link):not(.btn):not(.dropdown-item):not(.badge) {
  &.text-dark {
    &:hover {
      color: $primary !important;
    }
  }
  &.text-secondary {
    &:hover {
      color: $link-hover-color !important;
    }
  }
  &.text-muted {
    &:hover {
      color: $link-hover-color !important;
    }
  }
}

//Line clamp
.clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.clamp-2 {
  -webkit-line-clamp: 2;
}
.clamp-3 {
  -webkit-line-clamp: 3;
}

//text-white components variables
.text-white,
.text-light {
  --#{$prefix}link-color: #{$white};
  --#{$prefix}link-hover-color: #{$primary};
  .btn:not([class*='btn-']) {
    --#{$prefix}btn-color: #{$white};
  }
}

hr {
  --#{$prefix}hr-border-color: #{$border-color};
  border-top: $hr-border-width solid var(--#{$prefix}hr-border-color);
  opacity: $hr-opacity;
}
