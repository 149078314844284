//Custom apexcharts colors
.apexcharts-svg {
  max-width: 100% !important;
}
.apexcharts-inactive-legend {
  .apexcharts-legend-text {
    color: rgba(var(--#{$prefix}body-color), 0.4) !important;
  }
}
.apexcharts-text.apexcharts-yaxis-label,
.apexcharts-text.apexcharts-xaxis-label {
  fill: $apexchart-axix-label-color !important;
}
.apexcharts-xcrosshairs.apexcharts-active {
  fill-opacity: 0.25 !important;
}
.apexcharts-legend-text {
  display: inline-block;
  padding-left: 0.25rem;
  color: rgba(var(--#{$prefix}body-color), 0.75) !important;
}
.apexcharts-gridlines-horizontal line,
.apexcharts-gridlines-vertical line {
  stroke: var(--#{$prefix}border-color) !important;
}
.apexcharts-tooltip.apexcharts-theme-light {
  background-color: $apexchart-tooltip-bg !important;
  border: 0px !important;
}
.apexcharts-tooltip-title {
  margin-bottom: 0px !important;
  padding: 0.25rem 0.75rem !important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background-color: $apexchart-tooltip-title-bg !important;
  border: 0 !important;
  font-size: $apexchart-tooltip-title-size !important;
  color: $apexchart-tooltip-title-text !important;
  font-weight: $font-weight-bold !important;
}
.apexcharts-xaxistooltip {
  background-color: $apexchart-tooltip-bg !important;
  box-shadow: $apexchart-tooltip-shadow !important;
  border: 0 !important;
  &:before {
    border-bottom-width: 0px !important;
  }
  &:after {
    border-bottom-color: $apexchart-tooltip-bg !important;
  }
  .apexcharts-xaxistooltip-text {
    color: var(--#{$prefix}body-color) !important;
  }
}
#chart_revenue_report .apexcharts-series[seriesName='Expanses'] {
  transform: scaleY(1.035);
}
#chart_revenue_report .apexcharts-series[seriesName='Revenue'] {
  transform: scaleY(0.965);
}
#chart_bar .apexcharts-series[seriesName='Revenue'] {
  transform: scaleX(1.01);
}
#chart_bar .apexcharts-series[seriesName='Expanses'] {
  transform: scaleX(0.99);
}
.apexcharts-pie-area[selected='true'] {
  stroke-width: 0 !important;
}
.apexcharts-tooltip-series-group {
  padding: 0.25rem 0.75rem !important;
  &:not(:first-child) {
    border-top: $border-width solid var(--#{$prefix}border-color);
  }
  &:first-child {
    border-top: 0px !important;
  }
}
.apexcharts-heatmap-rect {
  stroke: $card-bg !important;
}
.apexcharts-pie-series {
  path {
    stroke: transparent !important;
  }
}
.apexcharts-xcrosshairs,
.apexcharts-ycrosshairs,
.apexcharts-xaxis-tick,
.apexcharts-radar-series.apexcharts-plot-series line,
.apexcharts-radar-series.apexcharts-plot-series polygon {
  stroke: var(--#{$prefix}border-color) !important;
}
.apexcharts-xaxis .apexcharts-datalabel {
  fill: $text-muted !important;
}
