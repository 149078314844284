//visibility
//Opacity
@use 'sass:math';
.opacity-0 {
  opacity: 0 !important;
}
.opacity-05 {
  opacity: 0.05 !important;
}
.opacity-100 {
  opacity: 1 !important;
}

//gradient-background
.bg-gradient-dark {
  background: $bg-gradient-dark;
}
.bg-gradient-primary {
  background: $bg-gradient-primary;
}
