//Site preloader

.loader {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  overflow: hidden;
}
