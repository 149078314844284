//
//Admin hover styles for images, links and more
//
.hover-lift,
[class*='hover-shadow-'],
.hover-shadow {
  transition: $transition-base;
}
.hover-lift {
  &:hover {
    transform: translateY(-4px);
  }
}
.card-hover {
  .hover-image {
    overflow: hidden;
    img {
      transition: all 0.3s;
    }
    .hover-image-overlay {
      background-image: linear-gradient(
        180deg,
        transparent 20%,
        rgba($black, 0.4) 100%
      );
      transition: all 0.2s;
      opacity: 0;
      visibility: hidden;
      span {
        display: inline-block;
        opacity: 0;
        transition: all 0.45s;
        transform: translateY(-10px);
      }
    }
  }

  &:hover {
    .hover-image {
      img {
        transform: scale(1.1);
      }
      .hover-image-overlay {
        visibility: visible;
        opacity: 1;
        span {
          transform: none;
          opacity: 1;
        }
      }
    }
  }
}

//Hover box shadows
.hover-shadow {
  box-shadow: none;
  &:hover {
    box-shadow: $box-shadow;
  }
}
.hover-shadow-sm {
  box-shadow: none;
  &:hover {
    box-shadow: $box-shadow-sm;
  }
}
.hover-shadow-lg {
  box-shadow: none;
  &:hover {
    box-shadow: $box-shadow-lg;
  }
}
