/* ------- customize primary color -------- */
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

$primary: #006688;
$success: #02743d;
.btn-primary:hover {
  box-shadow: none;
}

// merge with existing $theme-colors map
$theme-colors: map-merge(
  $theme-colors,
  (
    'primary': $primary,
    'success': $success,
  )
);

// set changes
@import 'bootstrap';

@import 'node_modules/@angular/material/theming';
@include mat-core();

//$my-app-primary: mat-palette($mat-indigo);
//$my-app-accent: mat-palette($mat-pink, A200, A100, A400);
//$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent);
//@include angular-material-theme($my-app-theme);

.bg-primary {
  background-color: $primary !important;
}

.border-primary {
  border-color: $primary !important;
}

.cdk-overlay-container {
  z-index: 5000;
}

.card h5 {
  font-size: $h5-font-size !important;
}

.mat-expansion-panel {
  background: none !important;
}

.page-sidebar .nav-item i {
  padding-right: 0.5rem;
}

.aside-logo {
  height: 102px;
  img {
    max-width: 150px;
  }
}

.navbar {
  img {
    max-height: 50px;
  }
}

.mat-typography {
  .toolbar,
  .not-found,
  .card {
    h1 {
      @extend %heading;
      @include font-size($h1-font-size);
    }
    h2 {
      @extend %heading;
      @include font-size($h2-font-size);
    }
    h3 {
      @extend %heading;
      @include font-size($h3-font-size);
    }
    h4 {
      @extend %heading;
      @include font-size($h4-font-size);
    }
    h5 {
      @extend %heading;
      @include font-size($h5-font-size);
    }
    h6 {
      @extend %heading;
      @include font-size($h6-font-size);
    }
  }
  .toolbar {
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 600;
      letter-spacing: 0.011rem;
    }
  }
}

.card-header.sticky {
  position: sticky;
  top: 60px;
  z-index: 10;
  background: #fff;
}

.btn {
  text-transform: unset !important;
}

.btn-default:hover {
  background-color: #f7f7f7;
  --bs-btn-focus-box-shadow: 0;
}

.btn.no-hover:hover {
  box-shadow: none;
}

.btn-default {
  border: 1px solid #dedede;
}

header.navbar {
  background-color: $primary;
  color: #fff;
  .nav-link {
    color: #fff;
  }
  h1 {
    color: #fff;
    margin-bottom: 0;
    margin-left: 1rem;
    font-weight: 500;
    letter-spacing: 0.02rem;
  }
}

aside .nav-link {
  --bs-nav-link-font-size: 20px;
}

.page-sidebar .nav-link,
.page-sidebar .nav-link[data-bs-toggle='collapse'],
.page-sidebar .sidebar-item .sidebar-link {
  padding: 0.5rem 0.5rem;
}

@media (min-width: 992px) {
  body.page-sidebar-mini .page-sidebar:hover {
    width: 84px;
  }
}

body.page-sidebar-mini .page-sidebar:hover .sidebar-text,
body.page-sidebar-mini .page-sidebar:hover .sidebar-dropdown,
body.page-sidebar-mini .page-sidebar:hover .logo-text,
body.page-sidebar-mini .page-sidebar:hover .sidebar-title > span,
body.page-sidebar-mini
  .page-sidebar:hover
  .nav-link[data-bs-toggle='collapse']::after,
body.page-sidebar-mini .page-sidebar:hover .aside-info {
  display: none;
}

aside.page-sidebar {
  display: none;
}

@media (min-width: 992px) {
  body.page-sidebar-mini .page-content {
    margin-left: 0;
  }
}

body.isAdmin {
  aside.page-sidebar {
    display: flex;
  }
}

@media (min-width: 992px) {
  body.page-sidebar-mini.isAdmin .page-content {
    margin-left: 84px !important;
  }
}
