//data tables style
div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_length {
  padding-top: $table-cell-padding-y;
  padding-left: $table-cell-padding-x;
  padding-bottom: $table-cell-padding-y;
}
div.dataTables_wrapper div.dataTables_paginate,
div.dataTables_wrapper .dataTables_filter {
  padding-right: $table-cell-padding-x;
  padding-top: $table-cell-padding-y * 0.75;
  padding-bottom: $table-cell-padding-y * 0.75;
}
.card {
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_length {
    padding-left: $card-spacer-x;
  }
  div.dataTables_wrapper .dataTables_filter,
  div.dataTables_wrapper div.dataTables_paginate {
    padding-right: $card-spacer-x;
    padding-left: $card-spacer-x;
  }
}
div.dataTables_wrapper > .row {
  align-items: center;
}
div.dataTables_wrapper .table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

table.dataTable.table-striped > tbody > tr.odd > * {
  box-shadow: inset 0 0 0 9999px var(--bs-table-striped-bg);
}
.dataTables_length label {
  display: flex;
  align-items: center;
  .form-select {
    max-width: 5rem;
    margin: 0 0.5rem;
    padding: 0.25rem 1rem 0.25rem 0.65rem;
  }
}
//Sorting icons
.dataTable .sorting {
  position: relative;
  cursor: pointer;
  padding-right: $table-cell-padding-x * 2;
  &::after {
    content: '\e5d7';
    position: absolute;
    right: 0;
    top: 0;
    width: $table-cell-padding-x * 2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: $font-size-base * 1.125;
    font-family: 'Material Symbols Rounded' !important;
  }
  &.sorting_asc {
    &::after {
      content: '\e5cf';
    }
  }
  &.sorting_desc {
    &::after {
      content: '\e5ce';
    }
  }
}
.dataTables_wrapper.dt-bootstrap5 {
  .row {
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    > [class^='col-'] {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .pagination {
    margin-bottom: 0px;
  }
}
.dataTables_paginate {
  display: flex;
}
@media (min-width: 768px) {
  .dataTables_paginate {
    justify-content: flex-end;
  }
}
