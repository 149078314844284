//Navs and Tabs
// Changing nav tabs to be bottom highlight style

.nav-tabs {
  --#{$prefix}nav-tabs-link-active-bg: transparent;
  .nav-item {
    margin-left: $nav-tabs-link-margin-x;
    margin-right: $nav-tabs-link-margin-x;
  }
  .nav-link {
    padding: $nav-tabs-link-padding-y 0;
    border-bottom: $nav-tabs-link-active-border-width solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
  }

  .nav-item:first-child {
    margin-left: 0;
  }

  .nav-item:last-child {
    margin-right: 0;
  }

  // Removes the primary color underline from dropdowns in .nav-tabs
  .nav-item.show .nav-link {
    border-color: transparent;
  }
  &.nav-vertical {
    flex-direction: column;
    border-bottom: 0px;
    .nav-item {
      margin-left: 0px;
      margin-right: 0px;
      &:not(:first-child) {
        margin-top: $nav-link-padding-y * 0.5;
      }
    }
    .nav-link {
      border-bottom-width: 0px;
      padding: $nav-link-padding-y $nav-link-padding-x;
      border-radius: $card-border-radius;
    }
  }
}

.nav:not(.nav-tabs):not(.nav-vertical) {
  &.flex-column {
    > .nav-link {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// Nav overflow
//
// Allow links to overflow and make horizontally scrollable

.nav-overflow {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1px; // to prevent active links border bottom from hiding
  // Hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
}

.nav-pills,
.nav-tabs {
  --#{$prefix}nav-link-color: var(--#{$prefix}body-color);
  --#{$prefix}nav-link-hover-color: #{$primary};
}
.nav-tabs {
  .nav-link {
    &.active,
    &:hover {
      border-bottom-color: var(--#{$prefix}primary);
    }
  }
}
