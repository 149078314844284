.content-wrapper {
  height: calc(100vh - 64px - 2rem);
  overflow: hidden;
  position: relative;
  display: flex;
  box-shadow: none;
  border-radius: $card-border-radius;
}
.content-sidebar {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-sidebar-header,
.content-right-header {
  height: 64px;
  display: flex;
  align-items: center;
}
.content-right-body,
.content-sidebar-body {
  flex: 1;
  overflow-y: auto;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
}
.content-right-body {
  display: flex;
  flex-direction: column-reverse;
}
.content-wrapper-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba($black, 0.25);
  backdrop-filter: blur(10px);
  transition: opacity 0.2s linear;
  opacity: 0;
  visibility: hidden;
  border-radius: $border-radius-lg;
}

.content-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    // stylelint-disable-next-line scss/selector-no-union-class-name
    &#{$infix} {
      @include media-breakpoint-up($next) {
        .content-left {
          width: 260px;
          float: left;
        }
        .content-right {
          width: calc(100% - 260px);
          float: right;
          margin-left: 0.5rem;
        }
        &.content-wrapper {
          height: calc(100vh - 64px - 2rem);
        }
      }
    }
  }
}

.content-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    // stylelint-disable-next-line scss/selector-no-union-class-name
    &#{$infix} {
      @include media-breakpoint-down($next) {
        .content-left {
          position: absolute;
          left: 0;
          top: 0;
          width: 290px;
          height: 100%;
          z-index: 10;
          transition: all 0.4s ease-in-out;
          opacity: 0;
          visibility: hidden;
          transform: translateX(-130%);
        }
        .content-right {
          width: 100%;
        }
        &.content_sidebar_show {
          .content-left {
            transform: none;
            opacity: 1;
            visibility: visible;
            z-index: 10;
            transition: all 0.4s;
          }
        }
        &.content_sidebar_show {
          .content-wrapper-overlay {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
